import React from "react";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import EmailIcon from "@mui/icons-material/Email";
import "../styles/Home.css";

function Home() {
  return (
    <div className="home">
      <div className="about">
        <h2>Hi, I'm Marcus.</h2>
        <div className="prompt">
          <p>A software developer with a passion for learning.</p>
          <LinkedInIcon />
          <GitHubIcon />
          <EmailIcon />
        </div>
      </div>
      <div className="skills">
        <h1>Skills</h1>
        <ol className="list">
          <li className="item">
            <h2>Front End</h2>
            <span>
              ReactJS, HTML5, CSS, Sass, NPM, Tailwind CSS, MaterialUI,
              Bootstrap
            </span>
          </li>
          <li className="item">
            <h2>Back End</h2>
            <span>
              NodeJS, ExpressJS, MySQL, OAuth, Heroku, Jest, PostgreSQL, .NET
            </span>
          </li>
          <li className="item">
            <h2>Languages</h2>
            <span>JavaScript, Python, C#</span>
          </li>
        </ol>
      </div>
    </div>
  );
}

export default Home;
