import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import SchoolIcon from "@mui/icons-material/School";
import WorkIcon from "@mui/icons-material/Work";
import "../styles/Experience.css";

function Experience() {
  return (
    <div className="experience">
      <VerticalTimeline lineColor="#3e497a">
        {/* // University*/}

        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2010 - 2012"
          iconStyle={{ background: "#3e497a", color: "#fff" }}
          icon={<SchoolIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            University of Oklahoma, Norman, OK
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Bachelor of Arts{" "}
          </h4>
          <p>Multidisciplinary Studies</p>
        </VerticalTimelineElement>

        {/* // Bootcamp */}

        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2010 - 2012"
          iconStyle={{ background: "#3e497a", color: "#fff" }}
          icon={<SchoolIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            BrainStation Software Engineering Bootcamp, New York, NY
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Diploma of Education
          </h4>

          <p>Software Engineering</p>
        </VerticalTimelineElement>

        {/* // Work */}

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="2010 - 2012"
          iconStyle={{ background: "#e9d35b", color: "#fff" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Freelance / Padawan
          </h3>
          <h4 className="vertical-timeline-element-subtitle">New York, NY</h4>

          <p>Developed mulitple small projects for clients</p>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </div>
  );
}

export default Experience;
