import DCM5772 from "../assets/DCM5572.jpg";
import bubblesort from "../assets/bubblesort.jpg";
export const ProjectList = [
  {
    name: "Spotlit",
    image: DCM5772,
    skills: "JavaScript, HTML, CSS",
  },
  {
    name: "Bubble Sort Visualizer",
    image: bubblesort,
    skills: "JavaScript, HTML, CSS",
  },
];
